body {
  overflow-x: hidden;
}

.react-datepicker-wrapper input {
  width: 100%;
  border-width: 0 0 2px 0;
  border-color: #CACACA;
  background: transparent;
  color: #5d5e60;
  font-size: 1rem;
  font-family: 'D-din';
  font-weight: 400;
  outline: none!important;
  padding: 4px 0 10px;
}
.react-datepicker-wrapper input:focus,
.react-datepicker-wrapper input:focus-visible {
  outline: none!important;
  border-color: #f1b80e!important;
  box-shadow: none!important;
}
.react-datepicker-wrapper input::placeholder {
  color: #6c757d;
}
.swal2-container {
  z-index: 10000!important;
}

.input-due {
  background: transparent;
  border-width: 0 0 2px;
  border-color: #CACACA;
  padding: 4px 0 5px;
  font-family: D-din;
  font-weight: 400;
  font-size: 1rem;
  color: #5d5e60;
}

.input-due:focus {
  outline: none!important;
  border-color: #f1b80e;
}

input.input-due[disabled] {
  border-bottom-style: dotted;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}